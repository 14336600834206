<template>
  <div class="settings-account">
    <teleport v-if="mountedComponent" to="#settings-header">
      <TopNavigation :links="topNavLinks" />
    </teleport>

    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
      </CBreadcrumbItem>

      <CBreadcrumbItem>
        <CSelectSearch
          v-model="selectedSetting"
          class="settings-account__select"
          :options="settingsList"
          :search="true"
        />
      </CBreadcrumbItem>
    </teleport>

    <router-view />
  </div>
</template>

<script>
import permissions from '@/utils/constants/permissions'
import TopNavigation from '@/components/Page/TopNavigation'
import breadcrumbsMixin from '@/views/Setting/breadcrumbsMixin'
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'Index',
  components: { TopNavigation },
  mixins: [breadcrumbsMixin],
  data() {
    return {
      mountedComponent: false,
      canAccountDetails: false,
      canView: false,
      canCompanyDetails: false,
    }
  },
  computed: {
    topNavLinks() {
      return [
        {
          title: 'My Profile',
          route: { name: 'MyProfile' },
        },
        {
          title: 'Account Details',
          route: { name: 'SettingAccountDetails' },
          permissions: [
            permissions.COMPANIES_VIEW,
            permissions.ACCOUNT_CONFIGURATION_VIEW,
          ],
          checkType: 'any',
          guard: () => {
            if (!this.canAccountDetails) this.$notify(permissionError)
            return this.canAccountDetails
          },
        },
        {
          title: 'Company Details',
          route: { name: 'SettingCompanyDetails' },
          guard: () => {
            if (!this.canCompanyDetails) this.$notify(permissionError)
            return this.canCompanyDetails
          },
        },
        {
          title: 'Employees',
          children: [
            'SettingCompanyUsersEdit',
            'InviteEmployee',
            'InvitedEmployees',
          ],
          permissions: [],
          route: { name: 'SettingCompanyUsersIndex' },
        },
        {
          title: 'Permission Groups',
          permissions: [],
          route: { name: 'SettingPermissionGroups' },
          guard: () => {
            if (!this.canView) this.$notify(permissionError)
            return this.canView
          },
        },
      ]
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.mountedComponent = true
      this.canAccountDetails = await Gate.can('accountDetails', 'setting')
      this.canView = await Gate.can('view', 'setting')
      this.canCompanyDetails = await Gate.can('companyDetails', 'setting')
    })
  },
}
</script>

<style lang="scss" scoped>
.settings-account {
  &__select {
    width: 162px;
  }
}
</style>
